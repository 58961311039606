<template>
  <div class="success toast-msg d-flex flex-row align-center" v-if="isShow">
    <div class="left-line green"></div>
    <div class="img-container">
      <!-- <img
        src="@/assets/icons/toast-msg/success.png"
        alt="success"
        width="28"
        height="22"
      /> -->
    </div>
    <div class="content-container">
      <h2>{{ successTitle }}</h2>
      <p>{{ successSubtitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MsgSuccess",
  props: {
    successTitle: {
      type: String,
      required: false,
      default: "Success",
    },
    successSubtitle: {
      type: String,
      required: false,
      default: "Your message has been sent successfully!",
    },
    timer: {
      type: Number,
      required: false,
      default: 2500,
    },
  },
  data() {
    return {
      isShow: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isShow = false;
      this.$emit("closePopup");
    }, this.timer);
  },
};
</script>

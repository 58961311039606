var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-us"},[_c('div',{staticClass:"wrapper d-flex flex-row"},[_c('div',{staticClass:"left d-flex flex-column"},[_c('h3',[_vm._v("Contact us")]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form d-flex flex-column",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{staticClass:"wrapper-input",attrs:{"rules":{ required: true, min: { length: 3 } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.name),expression:"user.name"}],class:{ 'is-invalid': errors.length },attrs:{"name":"name","type":"text","placeholder":"Your name"},domProps:{"value":(_vm.user.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "name", $event.target.value)}}}),(errors.length)?_c('img',{staticClass:"alert",attrs:{"src":require("@/assets/icons/alert.png"),"alt":"alert","width":"24","height":"24"}}):_vm._e(),_c('span',{staticClass:"errors-validator"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"wrapper-input",attrs:{"rules":{ required: true, email: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],class:{ 'is-invalid': errors.length },attrs:{"name":"email","type":"email","placeholder":"Your email"},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "email", $event.target.value)}}}),(errors.length)?_c('img',{staticClass:"alert",attrs:{"src":require("@/assets/icons/alert.png"),"alt":"alert","width":"24","height":"24"}}):_vm._e(),_c('span',{staticClass:"errors-validator"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"wrapper-input wrapper-input-textarea",attrs:{"rules":{
              required: true,
              min: { length: 3 },
              max: { length: 120 },
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.textarea),expression:"user.textarea"}],class:{ 'is-invalid': errors.length },attrs:{"name":"textarea","placeholder":"Enter your message here..."},domProps:{"value":(_vm.user.textarea)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "textarea", $event.target.value)}}}),(errors.length)?_c('img',{staticClass:"alert alert-textarea",attrs:{"src":require("@/assets/icons/alert.png"),"alt":"alert","width":"24","height":"24"}}):_vm._e(),_c('span',{staticClass:"errors-validator"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('span',{staticClass:"max-characters"},[(_vm.user.textarea.length)?_c('strong',[_vm._v(" "+_vm._s(_vm.user.textarea.length)+" /120 characters ")]):_c('strong',[_vm._v(" Max 120 characters")])]),_c('Button',{attrs:{"message":'Send',"type":"submit"}})],1)]}}])})],1),_vm._m(0)]),(_vm.isSended)?_c('MsgSuccess',{attrs:{"timer":2500},on:{"closePopup":function($event){_vm.isShowing = false}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"right"},[_c('h3',[_vm._v("Extra benefits in our app")]),_c('div',{staticClass:"wrapper-social"},[_c('img',{attrs:{"src":require("@/assets/img/Social/appstore.png"),"alt":"appstore","width":"180","height":"52"}}),_c('img',{attrs:{"src":require("@/assets/img/Social/googleplay.png"),"alt":"appstore","width":"180","height":"52"}})]),_c('img',{staticClass:"phone",attrs:{"src":require("@/assets/img/ContactUs/phone.png"),"alt":"phone","width":"347","height":"391"}})])}]

export { render, staticRenderFns }
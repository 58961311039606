<template>
  <div class="contact-us">
    <div class="wrapper d-flex flex-row">
      <div class="left d-flex flex-column">
        <h3>Contact us</h3>
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form
            class="form d-flex flex-column"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <ValidationProvider
              :rules="{ required: true, min: { length: 3 } }"
              v-slot="{ errors }"
              class="wrapper-input"
            >
              <input
                name="name"
                type="text"
                placeholder="Your name"
                v-model="user.name"
                :class="{ 'is-invalid': errors.length }"
              />
              <img
                v-if="errors.length"
                src="@/assets/icons/alert.png"
                alt="alert"
                width="24"
                height="24"
                class="alert"
              />
              <span class="errors-validator">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider
              :rules="{ required: true, email: true }"
              v-slot="{ errors }"
              class="wrapper-input"
            >
              <input
                name="email"
                type="email"
                placeholder="Your email"
                v-model="user.email"
                :class="{ 'is-invalid': errors.length }"
              />
              <img
                v-if="errors.length"
                src="@/assets/icons/alert.png"
                alt="alert"
                width="24"
                height="24"
                class="alert"
              />
              <span class="errors-validator">{{ errors[0] }}</span>
            </ValidationProvider>

            <ValidationProvider
              class="wrapper-input wrapper-input-textarea"
              :rules="{
                required: true,
                min: { length: 3 },
                max: { length: 120 },
              }"
              v-slot="{ errors }"
            >
              <textarea
                name="textarea"
                placeholder="Enter your message here..."
                v-model="user.textarea"
                :class="{ 'is-invalid': errors.length }"
              ></textarea>
              <img
                v-if="errors.length"
                src="@/assets/icons/alert.png"
                alt="alert"
                width="24"
                height="24"
                class="alert alert-textarea"
              />
              <span class="errors-validator">{{ errors[0] }}</span>
            </ValidationProvider>

            <span class="max-characters">
              <strong v-if="user.textarea.length">
                {{ user.textarea.length }} /120 characters
              </strong>
              <strong v-else> Max 120 characters</strong></span
            >
            <Button :message="'Send'" type="submit"></Button>
          </form>
        </ValidationObserver>
      </div>
      <div class="right">
        <h3>Extra benefits in our app</h3>
        <div class="wrapper-social">
          <img
            src="@/assets/img/Social/appstore.png"
            alt="appstore"
            width="180"
            height="52"
          />
          <img
            src="@/assets/img/Social/googleplay.png"
            alt="appstore"
            width="180"
            height="52"
          />
        </div>
        <img
          src="@/assets/img/ContactUs/phone.png"
          alt="phone"
          width="347"
          height="391"
          class="phone"
        />
      </div>
    </div>
    <MsgSuccess v-if="isSended" :timer="2500" @closePopup="isShowing = false" />
  </div>
</template>

<script>
import Button from "@/components/Button/CustomButton.vue";
import MsgSuccess from "@/components/MsgSuccess";
// import MsgError from "@/components/MsgError";

import { ValidationProvider, ValidationObserver, extend } from "vee-validate";

import * as rules from "vee-validate/dist/rules";
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

import { mapState } from "vuex";
import { actionTypes } from "@/store/modules/contactUs";

export default {
  name: "ContactUs",
  data() {
    return {
      user: {
        name: "",
        email: "",
        textarea: "",
      },
      submitted: false,
      textareaMax: "Max",
    };
  },
  computed: {
    ...mapState({
      isSended: (state) => state.contact.isSended,
      error: (state) => state.contact.error,
    }),
  },
  methods: {
    async onSubmit() {
      let payload = {
        name: this.user.name,
        email: this.user.email,
        message: this.user.textarea,
      };
      await this.$store.dispatch(actionTypes.sendContact, payload);

      if (this.isSended) {
        console.log("success", payload, this.isSended);
      } else {
        console.log("failed", this.isSended);
      }

      this.user.name = "";
      this.user.email = "";
      this.user.textarea = "";
      this.$refs.observer.reset();
    },
  },
  components: {
    Button,
    ValidationProvider,
    ValidationObserver,
    MsgSuccess,
  },
};
</script>
